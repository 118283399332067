import type { ItemModel, DataItemModel } from 'o365-dataobject';
import type PropertiesData from './DataObject.PropertiesData.ts';

import { Item as DataItem } from 'o365-dataobject';

type Constructor<T = {}> = new (...args: any[]) => T;
type DataItemConstructor<T extends ItemModel> = Constructor<DataItem<T>>;

export interface PropertiesItemModel<T extends ItemModel> extends DataItem<T> {
    properties: Record<string, any>;
    propertiesRows: Record<string, DataItemModel>;
    propertiesRowsArray: DataItemModel[];
    propertiesJSON: Record<string, any>
    isPropertiesLoading: boolean;

    error: DataItem<T>['error'];
    hasChanges: DataItem<T>['hasChanges'];
    isSaving: DataItem<T>['isSaving'];
    isDeleting: DataItem<T>['isDeleting'];
    loadingPromise: DataItem<T>['loadingPromise'];
    propertiesLoadingPromise: Promise<DataItemModel[]> | undefined;
    
    _initProperties(pOptions: PropertiesItemOptions<T>): void;
    loadProperties(): Promise<void>;
    resetProperties(): void;
}

function applyPropertiesMixin<T extends ItemModel, TBase extends DataItemConstructor<T>>(Base: TBase) {
    const PropertiesItem = class extends Base {
        private _getPropertiesData!: () => PropertiesData<T>;
        private _refreshProperties!: (pId: string | number) => Promise<DataItemModel[]>;
        private _properties?: Record<string, DataItemModel>;
        private _propertiesArray?: DataItemModel[];
        private _propertiesSetupError: string | null = null;
        private _propertiesLoadingPromise?: Promise<DataItemModel[]>;
        private _propertiesMap?: Record<string, any>;
        private _constructed = false;

        private get _propertiesError() {
            if (this._properties) {
                return this._propertiesArray!.find(x => x.error)?.error;
            } else {
                return null;
            }
        }

        get properties() {
            return this._propertiesMap;
        }
        get propertiesRows() {
            return this._properties;
        }
        get propertiesRowsArray() {
            return this._propertiesArray;
        }

        get propertiesJSON() {
            if (this._properties == null) { return undefined; }
            const mappedProps: Record<string, any> = {};
            Object.keys(this._properties).forEach(key => {
                const field = this._getPropertiesData().getValueField(key)
                if (field == null) { return; }
                mappedProps[key] = this._properties![key][field];
            });
            return mappedProps;
        }

        // --- DataItemModel Passthrough properties ---
        get isPropertiesLoading() {
            // Make sure to only start loading after item is initialized
            if (!this._constructed) { return true; }
            if (!this._properties) {
                this.loadProperties();
                return true;
            } else {
                return false;
            }
        }
        get error() { return this._propertiesError ?? this._state.error ?? this._propertiesSetupError; }
        get hasChanges() { return super.hasChanges || (this._propertiesArray?.some(prop => prop.hasChanges) ?? false); }
        get isSaving() { return super.isSaving || (this._propertiesArray?.some(prop => prop.isSaving) ?? false) }
        get isDeleting() { return super.isDeleting || (this._propertiesArray?.some(prop => prop.isDeleting) ?? false); }
        get loadingPromise() { return super.loadingPromise ?? this._propertiesLoadingPromise; }
        get propertiesLoadingPromise() { return this._propertiesLoadingPromise; }

        cancelChanges(pKey?: string) {
            if (pKey != null) {
                super.cancelChanges(pKey);
            } else {
                this._propertiesArray?.filter(item => item.hasChanges).forEach(item => {
                    item.cancelChanges();
                })
                super.cancelChanges();
            }
        }

        constructor(...args: any[]) {
            super(...args);
        }

        _initProperties(pOptions: PropertiesItemOptions<T>) {
            this._getPropertiesData = pOptions.getPropertiesData;
            this._refreshProperties = pOptions.refreshProperties;
            this._constructed = true;
        }

        async loadProperties() {
            if (this._propertiesLoadingPromise) { return; }
            const propertiesData = this._getPropertiesData();
            if (this.item[propertiesData.itemIdField] == null) { return; }
            try {
                this._propertiesLoadingPromise = this._refreshProperties(this.item[propertiesData.itemIdField]);
                const properties = await this._propertiesLoadingPromise;
                this._propertiesArray = properties;
                this._properties = {};
                for (const property of this._propertiesArray) {
                    this._properties![property[propertiesData.propertyField]] = property;
                    property.$.getParent = () => this;
                }

                this._propertiesMap = {};
                const that = this;
                if (propertiesData.selectedProperties) {
                    propertiesData.selectedProperties.forEach(selectedProperty => {
                        if (this._properties![selectedProperty]) {
                            const field = this._getPropertiesData().getValueField(selectedProperty);
                            if (field == null) { return; }
                            Object.defineProperty(this._propertiesMap, selectedProperty, {
                                get() { return that._properties![selectedProperty][field] ?? that._properties![selectedProperty]['Value'] },
                                set(value: any) { that._properties![selectedProperty][field] = value; that._properties![selectedProperty]['Value'] = value; }
                            });
                        } else[
                            Object.defineProperty(this._propertiesMap, selectedProperty, {
                                get() { return undefined; },
                                set(value: any) {
                                    that._assignEmptyProperty(selectedProperty, value);
                                },
                                configurable: true
                            })
                        ]
                    });
                } else {
                    Object.keys(this._properties).forEach(key => {
                        const field = this._getPropertiesData().getValueField(key);
                        if (field == null) { return; }
                        Object.defineProperty(this._propertiesMap, key, {
                            get() { return that._properties![key][field] ?? that._properties![key]['Value'] },
                            set(value: any) { that._properties![key][field] = value; that._properties![key]['Value'] = value }
                        })
                    });
                }


            } catch (ex) {
                this._propertiesSetupError = (ex as any)?.message ?? (ex as any)?.error ?? ex;
            } finally {
                if (this._properties == null) {
                    this._properties = {};
                }
            }
        }

        resetProperties() {
            this._propertiesLoadingPromise = undefined;
            this._properties = undefined;
            this._propertiesArray = undefined;
            this._propertiesMap = undefined;
        }

        private _assignEmptyProperty(pProperty: string, pValue: any) {
            if (this._propertiesMap == null || this._properties == null || this._properties[pProperty] != null) { return; }
            const propertyData = this._getPropertiesData();
            const idField = propertyData.itemIdField;
            if (this.item[idField] == null) { return; }
            const item = propertyData.getOrCreatePropertyItem(this.item[idField], pProperty);
            if (item) {
                const field = propertyData.getValueField(pProperty);
                item[field] = pValue;
                item['Value'] = pValue;
                this._properties[pProperty] = item;
                this._propertiesArray!.push(item);
                const that = this;
                Object.defineProperty(this._propertiesMap, pProperty, {
                    get() { return that._properties![pProperty][field] ?? that._properties![pProperty]['Value'] },
                    set(value: any) { that._properties![pProperty][field] = value; that._properties![pProperty]['Value'] = value; }
                });
            }
        }

    }

    // @ts-ignore
    return PropertiesItem;
}

export function getPropertiesItemModel<T extends ItemModel>(Base: any): Constructor<PropertiesItemModel<T>> {
    return applyPropertiesMixin<T, Constructor<DataItem<T>>>(Base) as any;
}

export type PropertiesItemOptions<T extends ItemModel = ItemModel> = {
    getPropertiesData: () => PropertiesData<T>;
    refreshProperties: (pId: string | number) => Promise<DataItemModel[]>;
};
